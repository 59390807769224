.createProposalPage a {
  color: var(--brand-dark-red);
  font-family: Changa;
}
.otherPageWrapper{
  width: 100%;
  height: calc(100vh - 176px);
}
.otherPageWrapper a{
  color: var(--bg-layout);
}

.createProposalForm {
  padding: 1rem 2.5rem;
  margin-top: 1em;
  background-color: var(--neutral-darkest);
  border-radius: 16px;
}
.createProposalForm .voterIneligibleAlert {
  background-color: var(--neutral-darker);
  color: var(--neutral-white);
  font-size: 16px;
  padding: 16px 20px;
  border-radius: 16px;
}
.createProposalForm .voterIneligibleAlert span {
  font-weight: 400;
  margin-left: 6px;
}
 {
  background-color: var(--neutral-darker);
  color: var(--neutral-white);
  font-size: 16px;
}

.heading {
  margin: 1rem 0;
  font-weight: 700;
  font-size: 36px;
  color: var(--neutral-white);
}

.section {
  border-top: 1px solid #e9ecef;
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.addTransactionButton {
  margin-top: 1rem;
  background-color: transparent;
  border: 2px solid var(--neutral-darker);
  width: 170px;
}
.createProposalButton {
  margin-top: 1rem;
  background-color: var(--primary-default);
  border: none;
  max-width: 340px;
  width:100%;
  height: 48px;
  color: var(--neutral-darkest);
  font-weight: 700;
}
.createProposalButton:focus {
  background-color: var(--primary-default);
  color: var(--neutral-darkest);
  border-color: var(--primary-default) !important;
  box-shadow: none;
}
.createProposalButton:hover {
  background-color: var(--primary-default);
  color: var(--neutral-darkest);
  border-color: var(--primary-default) !important;
  box-shadow: none;
}
.addTransactionButton:hover {
  background-color: transparent;
  border: 2px solid var(--neutral-darker);
}
.proposalEditorTitle {
  color: var(--neutral-white);
  font-family: 'Changa';
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
}

.titleInput {
  border: 1px solid var(--neutral-darker) !important;
  color: var(--neutral-white) !important;
  border-radius: 4px !important;
  width: 100% !important;
  background-color: transparent !important;
  font-size: 14px !important;
  box-shadow: none !important;
}
.btnBack {
  border-radius: 4px !important;
  border: 2px solid var(--neutral-darker) !important;
  padding:6px 14px !important;
  text-decoration: none !important;
}
