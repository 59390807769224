.proposals {
  display: flex;
  flex-direction: column;
}

.proposals > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.proposals > .ProposalsWrapperComing {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  height: calc(100vh - 300px);
  /* height: 100%; */
  padding: 1rem 0;
  overflow-y: auto;
}

.heading {
  font-family: 'Changa';
  font-weight: 700;
  font-size: 24px;
  color: var(--neutral-white);
  margin: 0 !important;
}

.generateBtn {
  width: 160px;
  height: 40px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-left: 12px;
  border-radius: 4px;
  background-color: var(--neutral-darkest);
  border: 2px solid var(--neutral-darker);
}
.generateBtn:focus,
.generateBtn:hover {
  background-color: var(--neutral-darkest);
  border: 2px solid var(--neutral-darker);
}
.generateBtn:active {
  background-color: var(--neutral-darkest);
  border: 2px solid var(--neutral-darker);
}

.generateBtnDisabled {
  max-width: 10rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  background-color: var(--neutral-darkest);
  border: 2px solid var(--neutral-darker);
  color: var(--neutral-darker);
}
.generateBtnDisabled:focus,
.generateBtnDisabled:hover,
.generateBtnDisabled:active {
  cursor: not-allowed;
  background-color: #f4f4f8 !important;
  border: #f4f4f8 !important;
  color: #8c8d92 !important;
  border: 1px solid #e2e3e8 !important;
  outline: none;
  box-shadow: none;
}

.proposalLink {
  padding: 1rem;
  margin-top: 0.4rem;
  max-width: 360px;
  min-width: 340px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: left;
  border-radius: 12px;
  background: var(--neutral-darker);
  font-size: 22px;
  font-family: 'Changa';
  font-weight: bold;
}

.proposalLink:hover {
  background: var(--neutral-darker);
  cursor: pointer;
}

.markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h3 {
  font-size: 1.3rem;
}

.markdown img {
  max-width: 100%;
  height: auto;
}

.nullStateCopy {
  margin-top: 0.9rem;
  margin-right: 1rem;
  font-family: 'PT Root UI';
  font-weight: 500;
  color: var(--brand-gray-light-text);
  font-size: 16px;
  line-height: 22.4px;
}

@media (max-width: 992px) {
  .nullStateCopy {
    margin-top: 0rem;
  }
  .proposalTitle {
    max-width: 65%;
    overflow-wrap: break-word;
  }
}

.nullBtnWrapper {
  min-width: 10rem;
}

.proposalId {
  color: var(--neutral-mid);
  font-family: 'Changa';
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
}

.proposalTitle {
  font-family: 'Changa';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.proposalStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 59px;
  height: 24px;
  gap: 4px;
}

.submitProposalButtonWrapper {
  text-align: right;
  padding-top: 0.8rem;
}
.submitProposalButtonWrapper span {
  text-align: right;
  padding-top: 0.8rem;
  color: var(--neutral-lighter);
}
.ProposalsWrapperComing {
  height: calc(100vh - 500px);
}
.ProposalsWrapper {
  display: grid !important;
  overflow: hidden;
  row-gap: 30px;
  gap: 30px 3rem;
  grid-template-columns: auto auto auto;
  margin: 0 auto;
  margin-top: 30px;
  overflow-y: auto;
  min-height: 350px;
}
.dateTime {
  font-family: 'Changa';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: var(--neutral-lighter);
  margin-top: 44px;
  display: flex;
  justify-content: left;
}
.dateTime img {
  margin-right: 6px;
}
.NotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.NotFound span {
  font-family: 'Changa';
  font-weight: 400;
  font-size: 14px;
  color: var(--neutral-mid);
}
@media (max-width: 1024px) {
  .ProposalsWrapper {
    display: grid;
    row-gap: 30px;
    gap: 30px 30px;
    grid-template-columns: auto auto;
    margin: 30px auto;
    overflow-y: auto;
  }
}
@media (max-width: 600px) {
  .ProposalsWrapper {
    display: grid;
    gap: 10px 10px;
    grid-template-columns: auto;
    margin: 30px auto;
    overflow-y: auto;
  }
}
.filterProposals {
  display: flex;
  align-items: center;
  border: 1px solid var(--neutral-darker);
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  height: 80px;
  overflow-x: auto;
  overflow-y: hidden;
}
.filterWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.selectStatus {
  width: 180px;
  height: 40px;
  background-color: transparent;
  text-align: left;
  color: var(--neutral-white);
  border: 1px solid var(--neutral-darker);
  border-radius: 4px;
  text-indent: 12px;
  text-transform: capitalize;
  font-size: 14px;
}
.selectStatus:hover,
.selectStatus:focus,
.selectStatus:active {
  background-color: transparent !important;
  border: 1px solid var(--primary-pressed) !important;
  outline: none !important;
}
.selectStatus:after {
  transform: rotate(-90deg);
  margin-left: 70px;
}
.dropdownBox {
  position: initial;
}
.selectStatusBox {
  background-color: var(--bg-layout);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  width: 180px;
  color: var(--neutral-white);
  margin-bottom: 22px;
}
.selectStatusBoxItem {
  color: var(--neutral-white);
}
.selectStatusBoxItem:hover {
  color: var(--primary-pressed);
  background-color: transparent;
  text-transform: capitalize;
}

.filterDate {
  background-color: transparent !important;
  height: 40px !important;
  min-width: 250px;
  border: 1px solid var(--bg-layout) !important;
}

.filterDate:focus {
  height: 40px !important;
  border: 1px solid var(--primary-pressed) !important;
}
.divider {
  height: 38px !important;
  border-left: 1px solid var(--bg-layout) !important;
  margin: 0 20px !important;
}
.clearAll {
  width: 60px;
  color: var(--primary-default);
  text-decoration: underline;
  justify-content: flex-end;
  cursor: pointer;
}
