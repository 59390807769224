.section {
  word-wrap: break-word;
  font-family: 'Changa';
  color: var(--neutral-white);
}

.section h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: 700;
  color: var(--neutral-white);
}
.section h4 {
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--neutral-white);
}

.markdown {
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--neutral-white);
}

.markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: 700;
  color: var(--neutral-white);
}

.markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: 700;
  color: var(--neutral-white);
}

.markdown h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: var(--neutral-white);
}

.markdown img {
  max-width: 100%;
  height: auto;
}

.container {
  display: flex;
}
.content {
  flex: 1;
}
.information {
  width: 350px;
  display: flex;
  flex-direction: column;
}
.infoCard {
  width: 100%;
  border: 1px solid var(--neutral-darker);
  border-radius: 12px;
  margin-bottom: 30px;
}

.cardHeader {
  color: var(--neutral-white);
  background: var(--neutral-darker);
  height: 44px;
  border-radius: 12px 12px 0 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-indent: 20px;
}
.cardBody {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
}
.cardBodyWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
.cardBodyLeft {
  font-size: 14px;
  color: var(--neutral-mid);
}
.cardBodyRight {
  font-size: 14px;
  display: flex;
}

.cardBodyRight img {
  margin-right:4px;
}

.cardNoData {
  flex:1;
  display: flex;
  align-items: center;
  height:220px;
  justify-content:center;
  color:var(--neutral-mid);
}

.cardCastVote{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:1rem 0;
}

.cardCastVote div{
  padding:10px;
  width:90%;
  height:3rem;
  text-align:center;
  border:2px solid var(--neutral-darker);
  background: transparent;
  margin:8px 0;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
}

.cardCastVote div:hover{
  padding:10px;
  width:90%;
  height:3rem;
  text-align:center;
  border:2px solid var(--primary-pressed);
  background: rgba(18, 206, 240, 0.12);
  border-radius: 4px;
}
.cardCastVote button{
  background:var(--primary-pressed);
  color: var(--neutral-darkest);
  width:90%;
  height:3rem;
  font-weight: 700;
  border-radius: 4px;
  border:none;
  font-size:16px;
}

@media (max-width:600px) {
  .container{
    display:flex;
    flex-direction:column;
  }
  .content{
    order:2;
  }
  .information{
    order:1;
    margin:auto;
    
  }
}
