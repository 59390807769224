@import './css/colors.css';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  background:var(--neutral-darkest);
  font-family: Changa !important;
  color:var(--neutral-white);
  font-weight: 500;
}
