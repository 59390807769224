.VoteHistoryModal {
  padding: 0;
  max-height: 30rem;
  font-family: Changa;
}
.VoteHistoryHeader {
  background: var(--bg-layout);
  border-radius: 12px 12px 0px 0px;
  padding: 12px 20px;
  border-bottom: none;
}
.VoteHistoryHeaderWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px 3rem;
  text-transform: uppercase;
  color: var(--neutral-lighter);
  font-weight: 700;
  font-size: 14px;
  width: 100%;
}
.VoteHistoryHeaderWrapper div {
  display: flex;
  align-items: center;
}

.VoteHistoryHeaderWrapper button {
    margin-bottom: 10px ;
  }

.VoteHistoryHeaderWrapper div img {
  margin-left: 6px;
  cursor: pointer;
}
.VoteHistoryBody {
  display: flex;
  flex-direction: column;
  padding: 0px;
  max-height: 100%;
  overflow-y: auto;
  text-transform: capitalize;
}
.rowBody {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  border-bottom: 1px solid rgba(147, 161, 170, 0.2);
  gap: 0px 2rem;
  margin: 0.5rem 0px;
  padding: 8px 20px;
  font-family: Changa;
  align-items: center;
}

.rowBody:last-child{
    border-bottom: none;
  }
.rowBodyVoter{
    display: flex;
    align-items: center;
}

.rowBodyVoter img{
    width: 30px;
    margin-right: 0.5rem;
    height: 30px;
    border-radius:50%;
}

.rowBodyVoterName{
    display: flex;
    flex-direction: column;
}

.rowBodyVoterName span:first-child{
    text-transform: capitalize;
}
.rowBodyVoterName span:last-child{
    color: var(--neutral-mid);
   
}
