.votePage a {
  color: var(--brand-dark-red);
}

.proposal {
  margin-top: 1em;
}

.backArrow {
  height: 1rem;
}

.votingButton {
  margin-top: 1rem;
}

.voteCountCard {
  margin-top: 1rem;
}

.proposalId {
  margin: 1rem 0;
}

.voteCountCard p {
  display: flex;
  justify-content: space-between;
}

.section {
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.section h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-family: 'Londrina Solid';
}

.voterIneligibleAlert {
  margin: 1rem 0 0 0;
}

.blockRestrictionAlert {
  margin: 1rem 0 0 0;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;

}

.transitionStateButton {
  height: 50px;
  border-radius: 8px;
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 24px;
  transition: all 0.125s ease-in-out;
}

.transitionStateButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.transitionStateButtonSection {
  border-top: 0px;
}

.spinner {
  margin-left: auto;
  margin-right: auto;
  color: var(--brand-gray-light-text);
}

/* Info section stuff */
.voteInfoCard {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 12px;
}

.voteMetadataRow {
  display: flex;
  justify-content: space-between;
}

.voteMetadataRow h1 {
  font-size: 20px;
  color: var(--brand-gray-light-text);
  font-family: 'Londrina Solid';
}

.voteMetadataRow span {
  font-size: 14px;
  font-family: 'PT Root UI';
  color: var(--brand-gray-light-text);
}

.voteMetadataRow h3 {
  font-size: 18px;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.voteMetadataRowTitle {
  margin-top: 0.5rem;
  width: max-content;
}

.voteMetadataTime {
  min-width: fit-content;
  text-align: right;
}

.snapshotBlock {
  text-align: right;
}

.thresholdInfo {
  text-align: right;
}
.votesHistory {
  color: var(--primary-default);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
}

.voteCardWrapper{
  display:grid;
  grid-template-columns: auto auto auto;
  gap: 0 30px;
}
@media (max-width: 600px){
  .voteCardWrapper{
    display:grid;
    grid-template-columns:auto;
    gap: 1rem 0;
    width:100%;
  }
  .wrapper{
    width:100%;
  }
}