.navBarBrand {
  position: relative;
  z-index: 2;
  transition: all 0.15s ease-in-out;
  font-family: Changa;
  font-weight: 500;
  background:var(--neutral-darkest);
 }

.navBarBrand:hover {
  transform: scale(0.95);
}

.navBarLogo {
  width: 56px;
  height: 46px;
  padding-right: 18px;
  border-right: 1px solid #20232B;
}

.navBarCustom {
  color:var(--neutral-white);
  font-family:Change UI;
  font-weight: 500;
  border-bottom: 1px solid #20232B;
  display: flex;
}

@media (max-width: 992px) {
  .navBarLogo {
    width: 65px;
    height: 65px;
  }

  .navBarCustom {
    color:var(--neutral-white);
    margin-bottom: -1px;
    margin-right: -5px;
    padding-bottom: 0rem;
  }
}

/* connect button displays differently to default NavBarItems, thus the !importants */
.connectBtn {
  cursor: pointer;
}

.connectBtn:hover,
.connectBtn:active,
.connectBtn:focus {
  cursor: pointer !important;
}

.disconnectBtn {
  cursor: pointer;
  font-family: Changa !important;
  font-weight: bold;
}

.disconnectBtn:hover,
.disconnectBtn:active,
.disconnectBtn:focus {
  cursor: pointer !important;
}

.greenStatusCircle {
  display: relative;
  width: 10px;
  height: 10px;
  background-color: #7fc59c;
  border-radius: 50%;
  margin-right: -30px;
  margin-left: 20px;
  margin-top: -20px;
  z-index: 3;
}

.nounsNavLink,
.nounsNavLink:active,
.nounsNavLink:visited {
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0 !important;
}

.testnetImg {
  width: auto;
  height: 45px;
}

.addressNavLink {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brandAndTreasuryWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-family: Changa;
  font-weight: 500;
}

.navBarToggle {
  border-radius: 10px;
  height: 44px;
  border-color:var(--neutral-darker)!important;
  color:#fff !important;
  padding: 0.25rem 0.5rem;
  margin-right: 12px;
  font-family: Changa !important;
}

.navBarToggle:focus {
  color:var(--primary-default)!important;
}

.navbar-toggler-icon {
  border-radius: 10px;
  height: 44px;
  border-color:var(--neutral-white)!important;
  color:#fff!important;
  padding: 0.25rem 0.5rem;
  margin-right: 12px;
  font-family: Changa !important;
}
.navBarCollapseWrapper{
  display: flex;
  width: 100%;
}

@media (max-width:768px){
  .navBarCollapseWrapper{
    flex-direction:column;
  }
  
}