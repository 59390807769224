.voteCardHeaderText {
  font-size: 18px;
  font-family: 'Changa';
  font-weight: 700;
}

.voteCardVoteCount {
  font-size: 18px;
  font-family: 'Changa';
  font-weight: 400;
}

.for {
  color: var(--accent-success);
}

.against {
  color: var(--accent-error);
}

.abstain {
  color: var(--brand-gray-light-text);
}

.voteCountCard {
  margin-top: 1rem;
  padding: 20px;
  border-radius: 12px;
  background-color: var(--bg-layout);
}

.voteCountCard p {
  display: flex;
  justify-content: space-between;
}

.nounProfilePics {
  margin-top: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media (max-width: 600px) {
  .voteCountCard {
    min-height: 0rem;
  }

  .voteCardVoteCount {
    margin-left: auto;
    margin-right: auto;
  }

  .wrapper {
    width: 100%;
  }

  .voteCardHeaderText {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
}
