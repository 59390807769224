.wrapper {
  background-color: #191A21;
  height: 70px;
  width: 100%;
}

.container {
  display: flex;
  color: var(--neutral-mid) !important;
  align-items: center;
  justify-content: center;
}

.footerSignature {
  margin: auto auto 0 auto;
  font-family: 'Changa' !important;
  font-weight: 400;
  font-size: 14px;
  padding-top: 2rem;
  color: var(--neutral-mid) !important;
}

.footerSignature a {
  text-decoration: none;
  color: black;
  margin: 8px 14px;
  transition: all 0.15s ease-in-out;
}

.footerSignature a:hover {
  text-decoration: none;
  font-weight: bold;
}

.footerSignature img {
  width: 32px;
  height: 32px;
  margin: 0 8px;
}

@media (max-width: 992px) {
  .footerSignature a {
    font-size: 16px;
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
