@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1170px !important;
  }
}

body {
  overflow-x: hidden;
}
.modal-content {
  background-color: var(--neutral-darker) !important;
  color: var(--neutral-white) !important;
  padding: 24px;
  width: 690px !important;
  border-radius: 16px !important;
}
.modal-dialog {
  max-width: 690px !important;
  max-height: 495px !important;
}
.btn-close {
  color: var(--neutral-white) !important;
  opacity:1 !important;
}
input[type='file']::file-selector-button {
  border-color: transparent !important;
  background-color: var(--bg-layout) !important;
  border: 1px solid var(--neutral-mid) !important;
  color: var(--neutral-white);
}
input[type='file']::file-selector-button:hover {
  border-color: transparent !important;
  background-color: var(--bg-layout) !important;
  border: 1px solid var(--neutral-mid) !important;
  color: var(--neutral-white);
}

.ql-toolbar.ql-snow {
  /* border: 1px solid #fff; */
  border: 1px solid #20232b !important;
  font-family: Changa;
  padding: 8px;
  background: var(--neutral-darker);
  border-radius: 4px 4px 0px 0px;
}

.ql-snow.ql-toolbar button {
  filter: brightness(0) invert(1);
}
.ql-toolbar.ql-snow .ql-picker-label {
  filter: brightness(0) invert(1);
}
.ql-container {
  font-family: Changa;
  border: 1px solid #20232b !important;
  border-radius: 0px 0px 4px 4px;
  height: 300px !important;
  overflow-y: auto !important;
}
.active span:first-child {
  background-color: var(--primary-default) !important;
  color: var(--neutral-darker) !important;
}
.completed span:first-child {
  background-color: var(--accent-success) !important;
  color: var(--neutral-darker) !important;
}

.navbar-toggler-icon {
  filter: brightness(0) invert(1);
}

.ant-picker {
  background-color: var(--bg-layout) !important;
  color: var(--neutral-white);
}

.ant-picker-separator span {
  color: var(--neutral-white);
}

.ant-picker-input input {
  color: var(--neutral-white) !important;
}
.ant-picker-clear {
  color: var(--neutral-white) !important;
  background: transparent !important;
}
.ant-picker-suffix {
  color: var(--neutral-white) !important;
}
.ant-picker-panel-container {
  background-color: var(--bg-layout) !important;
  color: var(--neutral-white) !important;
  border: none !important;
}
.ant-picker-panel {
  border: none !important;
}
.ant-picker-header {
  color: var(--neutral-white) !important;
  border-bottom: none !important;
}
.ant-picker-content th,
.ant-picker-cell-in-view,
.ant-picker-header,
.ant-picker-suffix,
.ant-picker-input input,
.ant-picker-cell {
  color: var(--neutral-white) !important;
  font-family: Changa;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before{
  background-color: var(--neutral-darkest) !important;
}
.ant-picker-cell-disabled{
  color: rgba(255, 255, 255, 0.5);
}
.ant-switch-checked{
  background: var(--primary-default) !important;
}

@media (max-width: 768px){
  .ant-picker-panels{
    flex-direction: column;
  }
}


