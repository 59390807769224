.otherWrapper {
  width: 100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.otherTitle {
  font-family: 'Changa';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
}
.otherLink{
    font-family: 'Changa';
    background-color: var(--primary-default);
    color:var(--neutral-darkest);
    border-radius:0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 3rem;
    border:none;
    font-weight:700;
    font-size: 16px;
    margin: 40px 0;
}

.otherLink:hover{
    color:var(--neutral-darkest);
}