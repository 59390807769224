.heading {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
}

.headerRow span {
  color: var(--neutral-mid);
  font-size: 20px;
  font-family: 'Changa';
}

.headerRow h1 {
  color: var(--neutral-white);
  font-size: 36px;
  font-family: 'Changa';
  font-weight: bold;
}

.subheading {
  color: var(--neutral-lighter);
  font-family: 'Changa';
  font-weight: 500;
  font-size: 20px;
}

.boldText {
  font-family: 'Changa';
  font-weight: bold;
}

p {
  padding-top: 1rem;
}

@media (max-width: 992px) {
  .section {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
}

.treasuryInfoCard {
  margin: auto;
  margin-bottom: 3rem;
  border-radius: 20px;
  background: url('../../assets/Treasury-bg.png') no-repeat center;
}

.treasuryAmtWrapper {
  padding:10px 30px;
}

.usdTreasuryAmt {
  color: var(--neutral-lighter);
  font-family: 'Changa';
  padding-top:6px;
}

.ethTreasuryAmt {
  display: flex;
  height: 3rem;
  min-width: 9rem;
  padding-top: 0.2rem;
  font-family: 'Changa';
}

.ethTreasuryAmt h1 {
  font-size: 36px;
  font-family: 'Changa';
  font-weight:700;
  color: var(--neutral-lighter);
}

.usdBalance {
  font-size: 36px;
  font-family: 'Londrina Solid';
}

.ethSymbol {
  font-family: 'Changa' !important;
  margin-right: 0.5rem;
}

.usdBalance {
  color: var(--brand-gray-light-text);
  font-family: 'Changa';
  font-weight: 500;
  font-size: 20px;
}

.treasuryInfoText {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: 'Changa';
  font-weight: 500;
}
