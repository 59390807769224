.wrapper {
  border-radius: 6px;
  height: 1rem;
}

.progressBar {
  height: 100%;
  border-radius: 6px;
}

.forWrapper {
  background-color: var(--neutral-darker);
}

.forProgressBar {
  background-color: var(--accent-success);
}

.againstWrapper {
  background-color: var(--neutral-darker);
}

.againstProgressBar {
  background-color: var(--accent-error);
}

.abstainWrapper {
  background-color: var(--neutral-darker);
}

.abstainProgressBar {
  background-color: var(--neutral-mid);
}
