.backButton {
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 1rem;
  margin-top: 0.1rem;
}

.headerRow span {
  color: var(--neutral-mid);
  font-size: 24px;
  font-family: 'Changa';
}
.headerRow h1 {
  color: var(--neutral-white);
  font-size: 36px;
  font-family: 'Changa';
}

.proposalStatus {
  margin-left: 0.75rem;
  margin-top: 0.1rem;
}

.connectWalletText {
  color: var(--brand-gray-light-text);
  font-family: 'Changa';
  font-weight: 500;
  font-size: 16px;
  min-width: 12rem;
  margin-bottom: 1.15rem;
}

.noVotesText {
  color: var(--brand-gray-light-text);
  font-family: 'Changa';
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1.15rem;
  min-width: 9.5rem;
}

.submitBtn {
  width: 100%;
  max-width: 8rem;
  min-width: 8rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-color-green);
  border: var(--brand-color-green);
}
.submitBtn:focus,
.submitBtn:hover {
  background-color: var(--brand-color-green) !important;
  box-shadow: 0 0 0 0.2rem rgb(67, 179, 105, 0.75);
}
.submitBtn:active {
  background-color: var(--brand-color-green) !important;
}
.submitBtnDisabled {
  background-color: var(--brand-gray-light-text) !important;
  width: 100%;
  max-width: 8rem;
  min-width: 8rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  border: var(--brand-dark-red);
  opacity: 0.5;
  box-shadow: none !important;
}

.submitBtnDisabled:hover,
.submitBtnDisabled:active,
.submitBtn:focus {
  cursor: not-allowed;
  box-shadow: none !important;
}

.proposalTitleWrapper {
  display: flex;
  padding-right: 2rem;
  max-width:50rem;
}

.proposalTitle {
  margin-right: 0.5rem;
}

.mobileSubmitProposalButton {
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 992px) {
  .submitBtn {
    max-width: 100%;
  }

  .submitBtnDisabled {
    max-width: 100%;
  }
}

.voterIneligibleAlert {
  font-family: 'Changa';
  font-weight: 500;
  background-color: #e2e3e8;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
 
}

.btnBack {
  border-radius: 4px !important;
  border: 2px solid var(--neutral-darker) !important;
  padding:6px 14px !important;
  text-decoration: none !important;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--neutral-white) !important;
}

