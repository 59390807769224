.proposalStatus {
  font-family: 'Changa';
  font-weight: 400;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  border: 0rem;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
}

.primary {
  background-color: var(--accent-information);
}

.success {
  background-color: var(--accent-success);
}

.danger {
  background-color: var(--accent-error);
}

.secondary {
  background-color: var(--neutral-mid);
}

.votePageProposalStatus {
  margin-top: 0.8rem;
}
